import React from 'react';
import page_found from "../../assets/ui-master/img/page_not_found.jpg";

const PageNotFound = () => {
  return (
    <div>
       <img src={page_found} alt="page_found" className="card-img-top" />
      </div>
  )
}

export default PageNotFound